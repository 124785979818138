<template>
  <footer id="footerBayer">
    <section id="footer__popup" class="footer__popup">
      <div class="popup__contenido">
        <span @click="cerrarPopup()" class="contenido__cierre"></span>
        <!-- <h3 class="contenido__titular"><b>Aviso de Farmacovigilancia</b></h3> -->
        <!-- <p class="contenido__texto">
          En el caso de que desee notificar un posible acontecimiento adverso /
          reacción adversa relacionado con algunos de nuestros medicamentos o
          productos sanitarios, contacte, por favor, con su médico o
          farmacéutico o bien siga este enlace
          <a href="#" title=""><b>https://www.notificaram.es</b></a> para
          realizar la notificación a la Agencia Española de Medicamentes y
          Productos Sanitarios.
        </p>

        <p class="contenido__texto">
          También puede notificar un posible acontecimiento adverso / reacción
          adversa directamente a Bayer a través del siguiente enlace: <br />
          <a href="#" title=""><b>www.safetrack-public.bayer.com</b></a> o
          ponerse en contacto con Bater llamando al teléfono
          <b>932 284 000</b> (centralita).
        </p>

        <p class="contenido__texto">
          También puede hacerlo a través del siguiente
          <b>formulario de Contacto</b>.
        </p> -->
        <p class="contenido__texto">
          Contacto para Farmacovigilancia
          <a href="https://www.bayer.com/es/es/espana-contacto" target="_blank"
            ><b>aquí </b></a
          >
        </p>
      </div>
    </section>

    <section class="footer__contenido">
      <div class="contenido__legal">
        <div class="legal__logo">
          <a href="/">
            <picture>
              <source
                srcset="https://d3jotkb1j9zbaa.cloudfront.net/logo_white.webp"
                type="image/webp"
              />
              <img
                loading="lazy"
                class="logo__bayer"
                width="77"
                height="77"
                src="https://d3jotkb1j9zbaa.cloudfront.net/logo_white.png"
                alt="Logo footer Bayer"
                title="Logo footer Bayer"
              />
            </picture>
          </a>
        </div>

        <div class="legal__enlace">
          <a href="/politica-de-privacidad" class="enlace__miga"
            >Política de Privacidad</a
          >
          <span class="enlace__miga cursor" @click="showPopup()"
            >Aviso de Farmacovigilancia</span
          >
          <a
            href="/ficha-tecnica"
            target="_blank"
            class="enlace__miga cursor"
            >Acceda al Contenido Mínimo de la Ficha Técnica</a
          >
          <a
            href="/referencias"
            target="_blank"
            class="enlace__miga cursor"
            v-if="this.$route.name.toLowerCase() == 'adiro'"
            >Referencias</a
          >
          <a
            href="https://www.bayer.com/es/es/espana-condiciones-generales-de-uso"
            target="_blank"
            class="enlace__miga cursor"
            >Condiciones de Uso</a
          >
          <a
            href="https://www.bayer.com/es/es/espana-pie-de-imprenta"
            target="_blank"
            class="enlace__miga cursor"
            >Pie de Imprenta</a
          >
          <a
            href="https://www.bayer.com/es/es/espana-contacto"
            target="_blank"
            class="enlace__miga cursor"
            >Contacto</a
          >
        </div>
      </div>

      <div class="contenido__legal--otro">
        <div class="contenido__numMaterial">
          <span class="numMaterial__codigo" v-if="this.$route.name.toLowerCase() == 'lafelguera'">PP-ADR-ES-0007-1 (10-2022)</span>
          <span class="numMaterial__codigo" v-else-if="this.$route.name.toLowerCase() == 'adiro'">PP-ADR-ES-0012-1 (11-2022)</span>
          <span class="numMaterial__codigo" v-else-if="this.$route.name.toLowerCase() == 'referencias'">PP-ADR-ES-0011-1 (11-2022)</span>
          <span class="numMaterial__codigo" v-else-if="this.$route.name.toLowerCase() == 'epil'">PP-ADR-ES-0016-1 (12-2022)</span>
          <span class="numMaterial__codigo" v-else>PP-ADR-ES-0007-1 (10-2022)</span>
        </div>
        <div class="contenido__disclaimer">
          <p class="disclaimer__texto">
            La página ha sido desarrollada por
            <a
              href="https://www.alebateducation.com/"
              target="_blank"
              class="texto__destacado"
              >Alebat</a
            >
            en el marco de una iniciativa de Bayer Hispania S.L.
          </p>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
import { animacionFooter } from "@/mixins/AnimacionFooter.js";
export default {
  name: "footerBayer",
  mixins: [animacionFooter],
};
</script>