import { gsap } from "gsap";

export const animacionFooter = {
    data() {
        return {
            popupOculto: true,
        };
    },
    methods: {
        showPopup() {
            let tamanioFooter = document.getElementById("footerBayer").clientHeight;
            if (this.popupOculto)
                gsap.to(".footer__popup", {
                    duration: 1,
                    bottom: tamanioFooter,
                    onStart: () => {
                        gsap.set(".footer__popup", { position: "absolute" });
                    },
                    onComplete: () => {
                        this.popupOculto = false;
                    },
                });
        },
        cerrarPopup() {
            let tamanioPopup = document.getElementById("footer__popup").clientHeight;
            if (!this.popupOculto)
                gsap.to(".footer__popup", {
                    duration: 1,
                    bottom: `-${tamanioPopup}`,
                    onStart: () => {
                        gsap.set(".footer__popup", { position: "fixed" });
                    },
                    onComplete: () => {
                        this.popupOculto = true;
                    },
                });
        },
    },
}